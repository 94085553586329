<template>
    <section>
        <div class="row mx-0">
            <div class="col px-0 overflow-auto custom-scroll border-right">
                <cargando v-if="cargando" />
                <div class="row mx-0 align-items-center px-2 my-1 border-bottom" style="height:40px;">
                    <div class="f-600 text-general f-15">
                        Productos en carrito
                    </div>
                    <div v-show="productos_agregados.length" class="btn-general f-14 px-3 ml-auto" @click="crearPedido">
                        Crear Pedido
                    </div>
                    <div v-show="productos_agregados.length" class="btn-action border ml-2" @click="vaciarCarrito">
                        <el-tooltip content="Vaciar Carrito" placement="bottom" effect="light">
                            <i class="icon-delete-trash" />
                        </el-tooltip>
                    </div>
                </div>
                <div v-show="ancho < 100" class="row mx-1 mt-2">
                    <div class="col border br-8 pt-3">
                        <div class="row mx-0 bg-light-f5 br-10" style="height:8px;">
                            <div class="bg-general br-10" :style="`width:${ancho}%;`" />
                        </div>
                        <p class="f-14 text-general2 mt-2">
                            Faltan {{ faltante }} para completar el pedido minimo
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div class="col-12 pr-0">
                        <p class="text-general f-15">
                            Simulador de cotizacion
                        </p>
                        <div class="row mx-0">
                            <div class="col-6 px-0">
                                <el-select v-model="valueLista" clearable placeholder="Seleccionar Clasificación" size="small" class="w-100">
                                    <el-option
                                    v-for="c in clasificaciones"
                                    :key="c.idLista"
                                    :label="c.nombre"
                                    :value="c.idLista"
                                    />
                                </el-select>
                            </div>
                            <div class="col-3 px-0 ml-3">
                                <div class="btn-general f-14 px-3 ml-auto" @click="setClasificacion(valueLista)">
                                    Calcular
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-2">
                    <p class="text-general f-17 f-600 px-2">Resumen</p>
                    <div class="row mx-0 px-2 text-general justify-content-between">
                        <p>Costo de productos ({{ resumen.cant_productos }})</p>
                        <b>{{ convertMoneyTendero(resumen.costo_productos,$tienda.idm_moneda) }}</b>
                    </div>
                    <div class="row mx-0 px-2 justify-content-between text-general">
                        <p>Descuento</p>
                        <b class="text-general-red">
                            {{ convertMoneyTendero(resumen.descuento,$tienda.idm_moneda) }}
                        </b>
                    </div>
                    <!-- <div v-if="resumen.val_condicion > 0" class="row mx-0 px-2 text-general justify-content-between">
                        <p>Alianza {{ $config.vendedor }}</p>
                        <b class="text-general-red">
                            {{ convertMoneyTendero(resumen.val_condicion,$tienda.idm_moneda) }}
                        </b>
                    </div> -->
                    <div v-if="resumen.val_descuento_lista > 0" class="row mx-0 px-2 text-general justify-content-between">
                        <p>Descuento Lista</p>
                        <b class="text-general-red">
                            {{ convertMoneyTendero(resumen.val_descuento_lista,$tienda.idm_moneda) }}
                        </b>
                    </div>
                    <div v-if="resumen.val_impuestos > 0" class="row mx-0 px-2 text-general justify-content-between">
                        <p>Impuestos</p>
                        <b>{{ convertMoneyTendero(resumen.val_impuestos,$tienda.idm_moneda) }}</b>
                    </div>
                    <div class="row mx-0 px-2 mt-2 text-general f-600 justify-content-between border-top pt-2">
                        <p>Total</p>
                        <p>{{ convertMoneyTendero(resumen.total,$tienda.idm_moneda) }}</p>
                    </div>
                    <!-- <div v-show="resumen.ganancia > 0" class="row mx-0 my-2 ticket br-8 py-1">
                        <div class="col pl-1 px-0 text-general f-15">
                            Tus ganancias
                        </div>
                        <div class="col-auto ml-auto total-value">
                            {{ convertMoneyTendero(resumen.ganancia, $tienda.idm_moneda) }}
                        </div>
                    </div> -->
                </div>

                <div class="p-2 f-15 overflow-auto custom-scroll chat-mensajes alto">
                    <div class="row mx-0 justify-center">
                        <card-producto
                        v-for="(prod, idx) in productos_agregados" :key="`prodf-${idx}`"
                        :producto="prod"
                        :diseno="prod.data_promocion"
                        @detalle="detalle_producto"
                        @change="cambio_cantidad"
                        @eliminar="eliminar_desconocido"
                        />
                    </div>
                    <contentLoader v-show="loading" />
                </div>
            </div>
        </div>
        <modalRealizarPedido ref="modalRealizarPedido" />
        <modalDetalleProducto ref="modalDetalleProducto" />
    </section>
</template>

<script>
import Almacen from "~/services/almacen/almacen"
import {mapGetters} from 'vuex'
export default {
    components: {
        cardProducto: () => import('./card-producto'),
        modalRealizarPedido: () => import('./modalRealizarPedido'),
        modalDetalleProducto: () => import('./modalDetalleProducto'),
    },
    data(){
        return {
            porcentaje: '',
            verDetalle: 0,
            loading:false,
            ancho:0,
            faltante:0,
            clasificaciones:[],
            valueLista:null,
            cargando:false
        }
    },
    computed:{
        ...mapGetters({
            productos_agregados: 'pedidos/pedidos_tendero/productos_agregados',
            resumen: 'pedidos/pedidos_tendero/resumen',
        }),
    },
    mounted(){
        this.lista_productos_carrito()
        this.obtener_resumen()
        this.getDescuentoLista()
    },
    methods: {
        async lista_productos_carrito(){
            try {
                this.loading = true

                await this.$store.dispatch('pedidos/pedidos_tendero/get_productos_carrito')


            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async getDescuentoLista(){
            try {
                const {data} = await Almacen.getDescuentoLista()
                this.clasificaciones = data
            } catch (e){
                this.error_catch(e)
            }
        },
        crearPedido(){
            if(parseInt(this.$tienda.convenio_nomina) == 1){
                this.vaciarCarrito();
                return this.notificacion('Alerta',`Lo sentimos, eres un ${this.$config.vendedor} de convenio nómina, solo los afiliados pueden hacer pedidos directamente`,'warning');
            }
            this.$router.push({name: 'tendero.carrito.checkout', params:{idLista:this.valueLista??null}})
        },
        async obtener_resumen(){
            await this.$store.dispatch('pedidos/pedidos_tendero/get_resumen')
            this.ancho = (this.resumen.total / this.resumen.pedido_minimo) * 100
            this.faltante = this.convertMoneyTendero(this.resumen.pedido_minimo - this.resumen.total,this.$tienda.idm_moneda)
        },
        async cambio_cantidad(obj){
            try {
                if(obj.producto.compra_maxima != 0){
                    if(obj.cantidad > obj.producto.compra_maxima){
                        return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto. Permitido '+obj.producto.compra_maxima+' Unidades','warning');
                    }
                } else {
                    if(obj.cantidad > 99999){
                        return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto.','warning');
                    }
                }
                let model = {
                    cantidad: obj.cantidad,
                    id_producto: obj.producto.id_producto
                }
                const {data} = await Almacen.alter_producto(model)

                if(data.eliminado){
                    let producto = obj.producto
                    producto.en_pedido = false
                    // console.log(producto);
                    this.$store.commit('pedidos/pedidos_tendero/alter_producto',producto)
                    this.$store.commit('pedidos/pedidos_tendero/remover_producto',producto.id_producto)
                    await this.$store.dispatch('pedidos/pedidos_tendero/get_resumen')
                    this.updatePedidoMinimo()

                    return

                }

                this.$store.commit('pedidos/pedidos_tendero/alter_agregados',data.producto)
                this.$store.commit('pedidos/pedidos_tendero/alter_producto',data.producto)
                await this.$store.dispatch('pedidos/pedidos_tendero/get_resumen')
                this.updatePedidoMinimo()

            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminar_desconocido(id){
            try {
                const {data} = await Almacen.eliminar_desconocido(id)
                this.$store.commit('pedidos/pedidos_tendero/remover_desconocido',id) // altera el carrito
                this.notificacion('','Registro eliminado','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        realizar_pedido(){
            this.$refs.modalRealizarPedido.toggle()
        },
        detalle_producto(producto){
            let prod = _.cloneDeep(producto)
            prod.precio = producto.total_teorico
            this.$refs.modalDetalleProducto.datos_cambio(prod)
        },
        updatePedidoMinimo(){
            this.ancho = (this.resumen.total / this.resumen.pedido_minimo) * 100
            this.faltante = this.convertMoneyTendero(this.resumen.pedido_minimo - this.resumen.total,this.$tienda.idm_moneda)
        },
        async vaciarCarrito(){
            try {
                const {data} = await Almacen.vaciarCarrito()
                this.notificacion('','Carrito vaciado correctamente','success')
                this.lista_productos_carrito()
                this.obtener_resumen()
                this.$store.commit('pedidos/pedidos_tendero/vaciar_productos')

            } catch (e){
                this.error_catch(e)
            }
        },
        async setClasificacion(idLista){
            try {

                this.cargando =true
                await this.$store.dispatch('pedidos/pedidos_tendero/get_productos_carrito')

                if(idLista == '' || !idLista){
                    await this.$store.dispatch('pedidos/pedidos_tendero/get_resumen')
                    return
                }

                const lista = this.clasificaciones.find(i => i.idLista == idLista)

                const valorTotal = parseFloat(this.resumen.costo_productos) - (parseFloat(this.resumen.descuento) + parseFloat(this.resumen.val_cupon));

                const descuentoCliente = lista.itemsCliente.find(i => i.desde <= valorTotal && i.hasta >= valorTotal)

                if(!descuentoCliente){
                    return this.notificacion('Alerta',`El valor del pedido no aplica para esta clasificacion`,'warning');
                }

                this.$store.commit('pedidos/pedidos_tendero/setProductosConDescuentoLista', descuentoCliente)
            } catch (error){
                console.log(error);
            } finally {
                this.cargando = false
            }


        }


    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 397px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}
@media(max-width:980px){
    .alto{
        height: calc(100vh - 360px);
    }
}
.br-t-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ticket {
  border: 1px solid #28d07b;
  background-color: #28d07b33;
  .total-value {
    color: #28d07b;
    font-weight: 600;
  }
}
</style>
